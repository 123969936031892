<template>
  <div class="industry">
    <div class="industry-second">
      <div class="industry-second-up">
        <!--        <v-header :current="current" @getData="getListData"></v-header>-->
        <a-form
          class="ant-advanced-search-form"
          :form="form"
          @submit="handleSearch"
        >
          <a-row class="ant-advanced-search-form-top" :gutter="24">
            <a-col :span="8">
              <!--<a-form-item label="行政区划：">-->
              <!--<a-select-->
              <!--mode="multiple"-->
              <!--style="width: 100%"-->
              <!--placeholder="请选择"-->
              <!--@change="handleChangeArea"-->
              <!--v-model="param.selectedAreaId"-->
              <!--&gt;-->
              <!--<a-select-option-->
              <!--:value="item.id"-->
              <!--v-for="(item, index) in xzqh"-->
              <!--:key="index"-->
              <!--&gt;-->
              <!--{{ item.name }}-->
              <!--</a-select-option>-->
              <!--</a-select>-->
              <!--</a-form-item>-->
              <a-form-item label="行政区划：">
                <a-cascader
                  :options="options"
                  @change="areaChange"
                  placeholder="请选择"
                  expandTrigger="hover"
                />
              </a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item label="水源类型：">
                <a-select
                  mode="multiple"
                  style="width: 100%"
                  placeholder="请选择"
                  v-model="param.waterType"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in waterTypes"
                    :key="index"
                    @change="handleChangeWaterType"
                  >
                    {{ item.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="工程名称：">
                <a-input placeholder="请输入工程名称" v-model="param.name" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row class="ant-advanced-search-form-top" :gutter="24">
            <a-col :span="8">
              <a-form-item label="供水规模：">
                <a-select
                  mode="multiple"
                  style="width: 100%"
                  placeholder="请选择"
                  v-model="param.wasuScale"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in wasuScales"
                    :key="index"
                    @change="handleChangeWasuScale"
                  >
                    {{ item.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="工程运行管理单位：">
                <a-select
                  mode="multiple"
                  style="width: 100%"
                  placeholder="请选择"
                  v-model="param.natManageUnit"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in natManageUnits"
                    :key="index"
                    @change="handleChangeNatManageUnit"
                  >
                    {{ item.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item label="年份：">
                <a-select
                  mode="multiple"
                  style="width: 100%"
                  placeholder="请选择"
                  v-model="param.reportYear"
                  @change="handleChange"
                >
                  <a-select-option v-for="i in year" :key="i">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4" :style="{ textAlign: 'right' }">
              <a-button type="primary" html-type="submit">
                查询
              </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                重置
              </a-button>
            </a-col>
          </a-row>
          <!--<div class="ant-advanced-search-form-bottom-all">
            <a-row class="ant-advanced-search-form-bottom">
              <a-col :span="24">
                <div class="industry-second-up-select">
                  <div class="industry-second-up-select-l">
                    供水规模：
                  </div>
                  <div class="industry-second-up-select-r">
                    <div
                      v-for="(item, index) in tabListd"
                      :key="index"
                      :class="
                        wasuScales.indexOf(item) !== -1 ? 'select-rd' : ''
                      "
                      @click="checkProject(item)"
                    >
                      {{ item }}
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24" :style="{ textAlign: 'right' }">
                <a-button type="primary" html-type="submit">
                  查询
                </a-button>
                <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                  重置
                </a-button>
              </a-col>
            </a-row>
          </div>-->
        </a-form>
      </div>
      <div class="industry-second-down">
        <div class="industry-second-down-top flex align-center justify-between">
          <div class="detail-top-left flex align-center">
            <div class="detail-top-left-shu"></div>
            <div class="detail-top-left-text">集中供水工程表</div>
          </div>
          <div class="flex align-center">
            <div
              class="more"
              @click="router('/index/industry/index/focusDetail')"
            >
              查看更多
            </div>
            <div>
              >
            </div>
          </div>
        </div>
        <!-- 表格 -->
        <div class="detail-table">
          <div class="detail-table-con">
            <div class="detail-table-content">
              <div class="detail-table-content-top flex align-center ">
                <div
                  class="detail-table-content-itemA width90 flex align-center justify-center"
                  :class="
                    index == 4 || index == 5 || index == 15 ? 'width200' : ''
                  "
                  v-for="(item, index) in columns"
                  :key="index"
                >
                  <div class="detail-table-content-itemA-con">
                    {{ item.title }}
                  </div>
                </div>
              </div>
              <div
                class="detail-table-content-list flex align-center"
                v-for="(item, index) in list"
                :key="index"
              >
                <div
                  class="detail-table-content-list-item flex align-center justify-center width90"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ (current - 1) * 20 + index + 1 }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item flex align-center justify-center width90"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.province }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item flex align-center justify-center width90"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.city }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item flex align-center justify-center width90"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.country }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item flex align-center justify-center width200"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.cwsName }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item flex align-center justify-center width200"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.cwsLoc }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item flex align-center justify-center width90"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.totalInvest }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item flex align-center justify-center width90"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.compDate }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item flex align-center justify-center width90"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.desWasuScal }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item flex align-center justify-center width90"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.yearWatter }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item flex align-center justify-center width90"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.desWasuPop }}
                  </div>
                </div>
                <!-- <div
                  class="detail-table-content-list-item flex align-center justify-center width90"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.waterPer }}
                  </div>
                </div> -->
                <div
                  class="detail-table-content-list-item flex align-center justify-center width90"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.waterPerH }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item flex align-center justify-center width90"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.waterType }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item flex align-center justify-center width90"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.waterQualityState }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item flex align-center justify-center width90"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.purWay }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item flex align-center justify-center width200"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.disinWay }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item flex align-center justify-center width90"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.resLiveCost }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item flex align-center justify-center width90"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.inOutCondition }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item flex align-center justify-center width90"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.proInstiName }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item flex align-center justify-center width90"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.natManageUnit }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="detail-table-page flex align-center">
          <template>
            <a-pagination
              :current="current"
              :total="total"
              @change="onChange"
              show-size-changer
              :page-size="pageSize"
              :pageSizeOptions="pageSizeOptions"
              @showSizeChange="onShowSizeChange"
            >
              <template slot="buildOptionText" slot-scope="props">
                <span>{{ props.value }}条/页</span>
              </template></a-pagination
            >
          </template>
        </div>

        <!-- <div class="industry-second-down-table-body">
          <div class="industry-second-down-table">
            <template>
              <a-table :columns="columns" :data-source="data" bordered>
              </a-table>
            </template>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    dataIndex: "name",
    key: "name",
    width: 120
  },
  {
    title: "省名称",
    dataIndex: "name",
    key: "name",
    width: 120
  },
  {
    title: "市名称",
    dataIndex: "name",
    key: "name",
    width: 120
  },
  {
    title: "县名称",
    dataIndex: "name",
    key: "name",
    width: 120
  },
  {
    title: "供水工程名称",
    dataIndex: "name",
    key: "name",
    width: 200
  },
  {
    title: "所在位置",
    dataIndex: "address",
    key: "address",
    width: 200
  },
  {
    title: "工程总投资（万元）",
    dataIndex: "num",
    key: "num 1",
    width: 103
  },
  {
    title: "开始供水时间",
    dataIndex: "compDate",
    key: "compDate",
    width: 90
  },
  {
    title: "设计供水能力(m³)",
    dataIndex: "num2",
    key: "num2",
    width: 103
  },
  {
    title: "年实际供水量(m³)",
    dataIndex: "num3",
    key: "num3",
    width: 103
  },
  {
    title: "供水人数（人）",
    dataIndex: "num4",
    key: "num4",
    width: 90
  },
  // {
  //   title: "实际供水人数（万人）",
  //   dataIndex: "num5",
  //   key: "num5",
  //   width: 103
  // },
  {
    title: "供水到户人数（人）",
    dataIndex: "num6",
    key: "num6",
    width: 105
  },
  {
    title: "水源类型",
    dataIndex: "num7",
    key: "num7",
    width: 90
  },
  {
    title: "水源水质状况",
    dataIndex: "num8",
    key: "num8",
    width: 90
  },
  {
    title: "净化方式",
    dataIndex: "num9",
    key: "num9",
    width: 90
  },
  {
    title: "消毒方式",
    dataIndex: "num10",
    key: "num10",
    width: 90
  },
  {
    title: "居民生活执行水价 （元/吨）",
    dataIndex: "num11",
    key: "num11",
    width: 123
  },
  {
    title: "收支情况",
    dataIndex: "num12",
    key: "num12",
    width: 90
  },
  {
    title: "工程运行管理单位名称",
    dataIndex: "num13",
    key: "num13",
    width: 105
  },
  {
    title: "工程运行管理单位性质",
    dataIndex: "num14",
    key: "num14",
    width: 105
  }
];

const data = [
  {
    key: "1",
    name: "三峡大坝",
    address: "山西省",
    num: "2999.99",
    num1: "2020",
    num2: "2999",
    num3: "2999",
    num4: "2999",
    num5: "2999",
    num6: "2999",
    num7: "2999",
    num8: "2999",
    num9: "2999",
    num10: "2999",
    num11: "2999",
    num12: "2999",
    num13: "2999",
    num14: "2999",

    tags: ["nice", "developer"]
  }
];

// 引人组件
import { getAreas, getDefaultArea } from "@/api/area/index";
import { baseMenuList } from "@/api/industry/index";
import { getBaseCwsePage } from "@/api/industry/focus";
import { getAreaTreeList } from "@/api/basic/common";

export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: "advanced_search" }),
      xzqh: ["江苏省", "北京市", "山西省"], //测试数据/行政区划
      year: [], //测试数据/年度
      waterTypes: [],
      natManageUnits: [],
      wasuScales: [], //切换工程运行管理单位index
      options: [
        {
          value: "1",
          label: "山西省"
        }
      ], //行政区划
      userAreaId: {
        id: 1
      },
      listParam: {
        areaIds: "1",
        name: "",
        natManageUnits: "",
        waterType: "0",
        wasuScales: "0",
        reportYear: "2020",
        updateMode: "0",
        pageSize: 5,
        pageNumber: 1
      },
      // 筛选条件
      param: {
        defaultAreaId: undefined,
        selectedAreaId: undefined,
        areaId: undefined,
        reportYear: undefined,
        waterType: undefined,
        natManageUnit: undefined,
        wasuScale: undefined,
        name: undefined
      },
      identity: 0,
      tabListd: [
        "全部",
        "县级水务局",
        "县级供水管理总站",
        "片区或流域等县级派生水管站（所）",
        "乡镇政府或水管站",
        "国有公司",
        "自来水公司",
        "股份制公司",
        "民营公司",
        "外资公司",
        "私人租赁或承包",
        "供水协会",
        "合作组织或用水户协会",
        "村委会",
        "其他"
      ], //工程运行管理单位
      data,
      columns,

      current: 1, //分页页码
      total: 0, //总数
      pageSize: 20,
      pageSizeOptions: ["20", "30", "40", "50"],
      list: []
    };
  },
  created() {
    var date = new Date();

    this.listParam.reportYear = date.getFullYear();
    this.year.unshift(date.getFullYear() - 1);
    for (let i = 0; i < 9; i++) {
      this.year = this.year.concat(date.getFullYear() + i);
    }
    let area = JSON.parse(localStorage.getItem("areaValue"));
    this.userAreaId.id = area.areaId;
    getAreaTreeList(this.userAreaId).then(response => {
      this.options = response.data.list;
    });
    this.getAreaList();
  },
  mounted() {},
  methods: {
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.getBaseCwse();
    },
    areaChange(value) {
      this.listParam.areaIds = value[value.length - 1];
      this.param.areaId = value[value.length - 1];
      console.log(value);
    },
    async getData() {
      await baseMenuList({
        menu: "水源类型"
      }).then(res => {
        // console.log('水源类型')
        // console.log(res)
        this.waterTypes = res.data.list;
      });
      await baseMenuList({
        menu: "工程运行管理单位性质"
      }).then(res => {
        // console.log('工程运行管理单位')
        // console.log(res)
        this.natManageUnits = res.data.list;
      });
      await baseMenuList({
        menu: "集中设施"
      }).then(res => {
        // console.log('工程运行管理单位')
        // console.log(res)
        this.wasuScales = res.data.list;
      });
      this.getBaseCwse();
    },
    getAreaList() {
      let data = getDefaultArea();
      console.log(data);
      this.param.defaultAreaId = data.id;
      this.identity = data.identity;
      this.param.areaName = data.name;
      this.param.reportYear = 2020;

      if (!this.param.areaId) {
        this.param.areaId = this.param.defaultAreaId;
      }

      getAreas({
        id: this.param.areaId
      }).then(res => {
        if (res.code == 200) {
          this.xzqh = res.data.list;
        }
        this.getData();
      });
    },
    //跳转
    router(x) {
      this.$router.push({
        path: x
      });
    },
    onChange(current) {
      this.current = current;
      this.getBaseCwse();

      // console.log(this.current);
    },
    // 切换工程运行管理单位
    checkProject(item) {
      if (this.wasuScales.indexOf(item) == -1) {
        this.wasuScales.push(item);
      } else {
        this.wasuScales.splice(
          this.wasuScales.findIndex(i => i === item),
          1
        );
      }
    },

    //查询
    handleSearch(e) {
      e.preventDefault();
      this.current = 1;
      this.getBaseCwse();
    },
    //清除
    handleReset() {
      this.param.areaId = this.param.defaultAreaId;
      this.param.selectedAreaId = undefined;
      this.param.reportYear = 2020;
      this.param.waterType = undefined;
      this.param.natManageUnit = undefined;
      this.param.wasuScale = undefined;
      this.param.name = undefined;
      this.current = 1;
      this.getBaseCwse();
    },
    //多选运行管理单位
    handleChange(value) {
      this.param.reportYear = value;
      console.log(`selected ${value}`);
    },
    handleChangeArea(value) {
      this.param.areaId = value;
      this.param.selectedAreaId = value;
    },
    handleChangeWaterType(value) {
      this.param.waterType = value;
    },
    handleChangeNatManageUnit(value) {
      this.param.natManageUnit = value;
    },
    handleChangeWasuScale(value) {
      this.param.wasuScale = value;
    },
    // 统计数据
    getBaseCwse() {
      let _this = this;
      _this.list = [];
      getBaseCwsePage({
        areaIds: _this.param.areaId,
        reportYear: _this.param.reportYear,
        waterTypes: _this.param.waterType,
        natManageUnits: _this.param.natManageUnit,
        wasuScales: _this.param.wasuScale,
        name: _this.param.name,
        pageNumber: _this.current,
        pageSize: this.pageSize
      }).then(res => {
        if (res.code == 200) {
          // 四率
          _this.list = res.data.list;
          _this.total = res.data.total;
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
.industry-second {
  width: 100%;
  .industry-second-up {
    padding: 1px;
    box-sizing: border-box;
  }
  .industry-second-ud {
    display: flex;
    padding: 20px 0 20px 0;
    .industry-second-udone {
      position: relative;
      cursor: pointer;
      width: 105px;
      text-align: center;
      div {
        width: 50px;
        height: 1px;
        background: #1890ff;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: -4px;
        display: none;
      }
    }
    .progress-tabone-select {
      color: #1890ff;
      div {
        display: block;
      }
    }
  }
  .industry-second-up-select {
    display: flex;
    align-items: flex-start;
    .industry-second-up-select-l {
      width: 100px;
      height: 40px;
      line-height: 40px;
    }
    .industry-second-up-select-r {
      display: flex;
      flex-wrap: wrap;
      div {
        height: 40px;
        margin-right: 10px;
        padding: 0 15px;
        text-align: center;
        line-height: 40px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .select-rd {
        color: #ffffff;
        background: #1890ff;
      }
    }
  }
}
.detail-top-left-shu {
  width: 4px;
  height: 16px;
  background: #1890ff;
  border-radius: 2px;
  margin-right: 8px;
}
.detail-top-left-text {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #28282c;
}
.more {
  cursor: pointer;
}
.ant-advanced-search-form {
  background: #ffffff;
  width: 100%;
  padding: 1px;
}
.ant-advanced-search-form-top {
  background: #ffffff;
  width: 100%;
  // height: 86px;
  // padding: 20px 0px;
  margin: 20px 0px;
  margin-left: 0px !important;
  display: flex !important;
  align-items: center;
}
.ant-row .ant-form-item {
  margin: 0px !important;
}
.ant-advanced-search-form-bottom-all {
  background: #ffffff;
  width: 100%;
  padding: 30px 20px 36px;
  box-sizing: border-box;
  margin-top: 20px;
}
.ant-advanced-search-form-bottom {
  width: 100%;
}
.industry-second-down {
  width: 100%;
  padding: 1px 20px 20px;
  box-sizing: border-box;
  background: #ffffff;
  margin-top: 20px;
}
.industry-second-down-top {
  padding: 20px 0px;
  // justify-content: flex-end;

  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.industry-second-down-table-body {
  overflow: auto;
}
.industry-second-down-table {
  width: max-content !important;
}

.detail-table {
  // padding: 52px 0px 0px 0px;
  width: 100%;
  // height: 700px;
  overflow: auto;
}
.detail-table-content {
  width: max-content;
  border-top: 1px solid #e1e1ee;
  border-left: 1px solid #e1e1ee;
}
.detail-table-content-top {
}
.table-active {
  top: -32px;
  left: 30px;
  width: 20px;
  height: 20px;
}
.table-activeB {
  top: -32px;
  left: 47%;
  width: 20px;
  height: 20px;
}
.width80 {
  width: 80px;
}
.width90 {
  width: 120px;
}
.width100 {
  width: 100px;
}
.width120 {
  width: 120px;
  height: 60px;
}
.width200 {
  width: 300px;
}

.detail-table-content-itemA {
  height: 60px;
  background: #fafafa;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
  // padding: 0px 10px;
  padding: 0px 8px;

  box-sizing: border-box;
}
.detail-table-content-itemA-con {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #28282c;
  line-height: 18px;
}
.detail-table-content-itemB {
  height: 120px;
  background: #fafafa;
  box-sizing: border-box;
}
.detail-table-content-itemB-top {
  height: 50px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #28282c;
  line-height: 50px;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
  text-align: center;
}
.detail-table-content-itemB-bottom-item {
  width: 80px;
  height: 70px;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
}
.detail-table-content-itemB-bottom-item-text {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  text-align: center;
  line-height: 16px;
}
.detail-table-content-list:hover {
  background: rgb(230, 247, 255);
}
.detail-table-content-list-item {
  height: 40px;
  text-align: center;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  // line-height: 20px;
}
.detail-table-page {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
